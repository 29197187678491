import logo from "./assets/logo.svg";
import cancel from "./assets/cancel.svg";

function Hamburger(props) {
  //remove user cookies on logging out
  function removeCookies() {
    document.cookie = "id=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  }
  return (
    <div className="hamburger-screen">
      <div className="top">
        <img
          src={cancel}
          className="remove-ham"
          onClick={() => props.toggleHamburger()}
        />
        <img className="logo" src={logo} />
      </div>
      {!props.user["ID"] ? (
        ""
      ) : (
        <div className="user-data-ham">
          <a className="user-img">{props.user["data"]["display_name"][0]}</a>
          <div className="ham-details">
            <a>{props.user["data"]["display_name"]}</a>
            <a>{props.user["data"]["name"]}</a>
          </div>
        </div>
      )}
      <div className="nevi">
       {
           props.showBooten == 'true' ?
                <div style={{marginRight:"25px",fontWeight: "bold"}}>
                <a className="faq-link" onClick={props.goToCategoris} >
                  {props.name_catgory}          
                </a>
          </div>
                :<div></div>
              }
        <a
          onClick={() => props.toggleHamburger()}
          href="#faq"
          className="nev-item"
        >
          <h4>שאלות נפוצות</h4>

        </a>

        {
                props.showBooten == 'true' ?
                <div style={{fontWeight: "bold"}}>
                <a className="nev-item" onClick={props.goToCategoris} >
                  {props.name_catgory}          
                </a>
          </div>
                :<div></div>
              }
        {/* <a className="nev-item" onClick={props.goToSecretBoom}>
              בומיים אישים
            </a> */}

        {!props.user["ID"] ? (
          <a
            onClick={() => {
              props.toggleHamburger();
              props.goToRegister();
            }}
            className="nev-item"
          >
            <h4>התחברות / הרשמה</h4>
          </a>

        ) : (
          <a
            onClick={() => {
              props.toggleHamburger();
              props.removeUser();
              removeCookies();
            }}
            className="nev-item"
          >
            <h4>יציאה</h4>{" "}
          </a>
        )}

         
      </div>
    </div>
  );
}

export default Hamburger;
