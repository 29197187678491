//react hooks
import React, { useState, useEffect } from "react";
import Favicon from "react-favicon";
import Footer from "./Footer.js";
import Header from "./Header.js";
import Loop from "./Loop.js";
import Banner from "./Banner.js";
import main from "./assets/main.png";
import ProdactCategory from "./ProdactCategory.js";
import img_a from "./product-imgs/product_a.svg";
import arrow_right from "./assets/arrow-right.svg";
import arrow_left from "./assets/arrow-left.svg";
import OffProduct from "./OffProduct.js";


//component
function Categoris(props) {
const [products, setProducts] = useState([{}]);
  const [options, setOptions] = useState([{}]);
  const [loading, setLoad] = useState(0);
    const [grab, setGrab] = useState(0);
  const [grads, setGrads] = useState({});
  const [reminderUser, setReminderUser] = useState(0);
  const [userProdact, setUserProdact] = useState(0);
const currentUrl = window.location.hostname + "/" + products["id"];
const shareText = "הוזמנת לצפות בקופון מטורף, כנסו עכשיו!!" + currentUrl;

// useEffect(async () => {
//       // const _id =props.options.categoryid;
//       const _id =props.id;

//       async function fetchMyAPI() {
//         try {
//             let response = await fetch(`https://manage.boombuy.co.il/wp-json/react/v1/products/category/${_id}`,
//         {
//           method: "GET",
//         })
//          .then(async(response) => await response.json())
//       .then((resData) => {
//         setProducts(resData);
//         setTimeout(function () {
//           setLoad(1);
//         }, 250);
//       });
//         } catch (e) {
//             console.error(e);
//         }
       

//       }
//       fetchMyAPI()
//   }, [products]);
  let max_h = props.width > 900 ? "55vh" : "20px";
    return (
    <div className="categoris App">
        <div className="bomim-categoris padding2">
{/* 
         <div className="column" style={{flex: "0 0 45%", justifyContent: "center",display: "flex", paddingRight: "75px"}} >
            <div className="row bomim-categoris-titel">
                <div className="bomim-categoris-titel bold" style={{paddingLeft: "11px"}}>{props.titelSpecile}</div><div className="bomim-categoris-titel">בומים שלא כדאי לפספס</div>
            </div>
            <div className="bomim-categoris-text"> {props.textSpecile} </div>
         </div> */}
         <div >
         <img style={{justifyContent: "center", width: "95.2353vw"}}  src={`${props.image}`}/>
         </div>

        </div>
        <button className="back" style={{}} onClick={props.goBack}>
          חזרה
        </button>
        <div className="padding2 " style={{display: "flex", flexWrap: 'wrap', justifyContent:'center'}}>

        {props.products.length > 0 ? (
           (props.products.map((single, index) =>{
           
              {return props.products[index]["id"] &&(
              <ProdactCategory
                  key={index}
                  userData={props.user}
                  time={props.time}
                  render={"category"}
                  productData={props.products[index]}
                  // changePage = {() => {props.changePage(index)}}
                  changePage_andMain={() => {
                    props.changeMain(index);
                    props.changePage(props.products[index]["id"]);
                  
                  }}
              />  )}
              
                }
              ))
            ) : (
              <p>No posts</p>
            )}    

        </div>

        <div className="padding2"> 

        </div>
        <div>
        <Footer
        // changePage={(i) => {
        //   setPrevPage(page);
        //   setPage(i);
        //   window.history.pushState({}, "", "/" + i);
        //   loadPage();
        // }}
      /></div>
     
    </div>
  );


}

export default Categoris;
